/* Container Styling */
.privacy-policy {
    font-family: 'Arial', sans-serif;
    background: linear-gradient(to bottom, #f5f5f5, #ffffff);
    color: #333;
    padding: 20px;
  }
  
  .container {
    max-width: 1300px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Typography */
  .title {
    font-size: 2.5rem;
    color: #b03a2e; /* Custom color */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .update-date {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 1.8rem;
    color: #b03a2e; /* Custom color */
    margin-bottom: 15px;
  }
  
  p, li {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* List Styling */
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  /* Address Styling */
  address {
    font-style: normal;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    .title {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p, li {
      font-size: 0.95rem;
    }
  }
  