.about-container {
    display: flex;
    align-items: center;
    background-color: #F9F4E8; 
    background-image: url(../../Images/about-one-shape-1.png);
    padding: 50px;
    gap: 69px;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.image-container {
    width: 47%;
}

.image-container img {
    width: 100%;
    height: 100%;
}

.text-container {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.text-container p {
    margin: 0;
    padding: 10px 0;
}

.text-container button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #F5812A;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 25%;
}

.text-container button:hover {
    background-color: #e57300;
}

.heading-about {
    color: #e57300;
    font-size: 45px;
    font-weight: 900;
}

.welcome-about {
    color: #e57300;
    font-weight: 500;
}

.paragraph-about {
    font-size: 20px;
    font-weight: 500;
}

/* Breadcrumb styles */
.breadcrumb-container {
    position: relative;
    text-align: center;
    color: white;
    height: 125px;
    margin-bottom: 20px;
}

.breadcrumb-image {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.breadcrumb-image img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    background-blend-mode: luminosity;
}

.breadcrumb-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
    .about-container {
        padding: 40px;
        gap: 40px;
    }

    .image-container, .text-container {
        width: 100%;
    }

    .text-container button {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .about-container {
        padding: 30px;
        gap: 20px;
        flex-direction: column; /* Stack elements vertically on small screens */
    }

    .heading-about {
        font-size: 35px;
    }

    .paragraph-about {
        font-size: 18px;
    }

    .text-container button {
        width: 60%;
    }
}

@media (max-width: 480px) {
    .about-container {
        padding: 20px;
        gap: 10px;
    }

    .heading-about {
        font-size: 28px;
    }

    .paragraph-about {
        font-size: 16px;
    }

    .text-container button {
        width: 80%;
    }
}
