.footer-section {
  position: relative;
  background-color: #283734d6;
  color: #fff;
  padding: 40px 20px;
  text-align: left;
  overflow: hidden;
}

.footer-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../Images/footerimage.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(5px);
  z-index: -1;
}

.footer-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure elements are in a single row */
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
}

.footer-donate, .footer-links, .footer-contact {
  flex: 1;
  margin: 10px;
  min-width: 0; /* Prevent overflowing */
}

.footer-donate-content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.footer-donate-content p {
  margin-bottom: 20px;
  font-size: 18px;
}

.footer-donate-button {
  background-color: #f5812a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.footer-donate-button:hover {
  background-color: #e07b27;
}

.footer-links, .footer-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the contents to the start of the column */
}

.footer-links h3, .footer-contact h3 {
  margin-bottom: 20px;
  font-size: 20px;
  border-bottom: 2px solid #f5812a;
  padding-bottom: 5px;
}

.footer-links h4 {
  margin: 5px 0;
  font-size: 16px;
  cursor: pointer;
}

.footer-links h4:hover {
  color: #f5812a;
}

.footer-contact p {
  margin: 5px 0;
  font-size: 16px;
}

.footer-contact i {
  margin-right: 10px;
}

.footer-social {
  margin-top: 20px;
  text-align: center;
}

.footer-social i {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
}

.footer-social i:hover {
  color: #f5812a;
}

.copyright {
  text-align: center;
  padding: 20px;
  background-color: #212D2B;
  color: white;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer-donate, .footer-links, .footer-contact {
    flex: 1 1 30%; /* Adjust width to fit on larger screens */
  }
}

@media (max-width: 768px) {
  .footer-container {
    flex-wrap: wrap;
  }

  .footer-donate, .footer-links, .footer-contact {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .footer-section {
    padding: 30px 15px;
  }

  .footer-donate-content p,
  .footer-contact p,
  .footer-links h4 {
    font-size: 14px;
  }

  .footer-donate-button {
    padding: 8px 16px;
  }

  .footer-links h3,
  .footer-contact h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .footer-section {
    padding: 20px 10px;
  }

  .footer-donate-content p,
  .footer-contact p,
  .footer-links h4 {
    font-size: 12px;
  }

  .footer-donate-button {
    padding: 6px 12px;
  }

  .footer-links h3,
  .footer-contact h3 {
    font-size: 16px;
  }

  .footer-social i {
    font-size: 20px;
    margin: 0 8px;
  }
}
