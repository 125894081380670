/* Base styles */
.return-and-refund-policy {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .update-date {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  address {
    font-style: normal;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .title {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.25rem;
    }
  
    .container {
      padding: 0 15px;
    }
  
    ul {
      margin-left: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.25rem;
    }
  
    h2 {
      font-size: 1rem;
    }
  
    .update-date {
      font-size: 0.8rem;
    }
  
    ul {
      margin-left: 10px;
    }
  
    address {
      font-size: 0.9rem;
    }
  }
  