.twocard-section {
  padding: 50px 10%; /* Adjusted padding for better responsiveness */
  background-color: #f5f5f5;
  text-align: center;
}

.twocard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap; /* Wrap cards on smaller screens */
}

.twocard {
  background: #F5812A;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 50px 20px; /* Padding for cards */
  text-align: center;
  width: 100%; /* Full width on smaller screens */
  max-width: 48%; /* Maximum width for larger screens */
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Margin to separate cards */
  height: 300px;
}

.twocard:hover {
  transform: scale(1.05);
}

.twocard-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px; /* Reduced gap for smaller screens */
}

.icon-container {
  font-size: 50px;
  color: #333;
  margin-bottom: 10px;
}

.text-container-two p {
  font-size: 45px; /* Default font size */
  font-weight: bold;
  color: white;
  text-align: center; /* Centered text for better look */
  line-height: 40px;
  cursor: pointer;
}

.twocard-button {
  background-color: white;
  border: none;
  border-radius: 20px;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 35px;
  font-size: 16px;
  cursor: pointer;
  width: auto; /* Adjust width for better touch targets */
  max-width: 200px; /* Max width for button */
  transition: background-color 0.3s ease-in-out;
  margin: 20px auto; /* Centered the button and added margin */
}

.twocard-button:hover {
  background-color: #fbd45a;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .twocard {
    max-width: 48%; /* 2 cards per row on medium screens */
    padding: 40px 20px; /* Adjust padding for medium screens */
  }

  .twocard-button {
    width: auto; /* Width adjusted for medium screens */
  }
}

@media (max-width: 768px) {
  .twocard {
    max-width: 100%; /* Full width on smaller screens */
    padding: 30px 20px; /* Adjust padding for small screens */
  }

  .text-container-two p {
    font-size: 20px; /* Adjust font size for small screens */
  }

  .twocard-button {
    max-width: 150px; /* Max width for button on small screens */
  }
  .text-container-two p {
    line-height: 19px;
}
}
@media (max-width: 480px) {
  .twocard {
    max-width: 100%; /* Full width on extra small screens */
    padding: 20px 10px; /* Adjust padding for extra small screens */
    height: 200px;
  }

  .text-container-two p {
    font-size: 18px; /* Adjust font size for extra small screens */
  }

  .twocard-button {
    max-width: 1660px; /* Max width for button on extra small screens */
  }
  .text-container-two p {
    line-height: 19px;
}
}
