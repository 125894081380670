.know-section {
  padding: 20px;
  background-color: #F5812A;
  text-align: center;
}

.know-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.know-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
  padding: 20px 0;
  text-align: center;
  width: 280px;
  transition: transform 0.3s ease-in-out;
}

.know-card:hover {
  transform: scale(1.05);
}

.icon-container {
  font-size: 50px;
  color: #333;
  margin-bottom: 10px;
}

.text-containers p {
  font-size: 25px;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.text-containers p:hover {
  transition: 0.1s;
  color: #FBD45A;
}

.icon-container i {
  color: #FBD45A;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .know-container {
      gap: 20px;
  }

  .know-card {
      width: 220px;
      padding: 15px 0;
  }

  .icon-container {
      font-size: 40px;
  }

  .text-containers p {
      font-size: 22px;
  }
}

@media (max-width: 768px) {
  .know-container {
      gap: 15px;
  }

  .know-card {
      width: 200px;
      padding: 10px 0;
  }

  .icon-container {
      font-size: 35px;
  }

  .text-containers p {
      font-size: 20px;
  }
}

@media (max-width: 480px) {
  .know-container {
      gap: 10px;
  }

  .know-card {
      width: 180px;
      padding: 8px 0;
  }

  .icon-container {
      font-size: 30px;
  }

  .text-containers p {
      font-size: 18px;
  }
}
